import React, { FC } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { InputPhone } from '../InputPhone';

interface PhoneFieldProps extends FieldRenderProps<string, HTMLInputElement> {}

const PhoneField: FC<PhoneFieldProps> = ({ input, meta, ...rest }) => {
  return <InputPhone input={input} meta={meta} {...rest} />;
};

export default PhoneField;
