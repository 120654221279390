import { StyledSelect } from '@/ui/components/StyledSelect';
import React, { FC } from 'react';
import { FieldRenderProps } from 'react-final-form';

interface FieldSelectProps extends FieldRenderProps<string, any> {}

const FieldSelect: FC<FieldSelectProps> = ({ input, meta, ...rest }) => {
  const isValid = !meta.visited && !meta.valid ? false : true;

  return <StyledSelect {...input} {...rest} />;
};

export default FieldSelect;
