import { LocaleContext } from '@/contexts/LocaleContext';
import dayjs from 'dayjs';
import React, { forwardRef, useContext } from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { Input } from '../Input';

import { Header } from './components';

type InputDateProps = {
  onClick?: () => void;
  value?: string;
  onChange?: () => void;
};

const InputDate = forwardRef<HTMLInputElement, InputDateProps>(
  function InputDate({ onChange, value, onClick, ...props }, ref) {
    return (
      <Input
        onClick={onClick}
        ref={ref}
        value={value}
        mask="99.99.9999"
        onChange={onChange}
        className="react-datepicker-ignore-onclickoutside"
        {...props}
      />
    );
  },
);

const StyledDatePicker = forwardRef<DatePicker, ReactDatePickerProps>(
  function StyledDatePicker(props, ref) {
    const locale = useContext(LocaleContext);

    return (
      <div>
        <DatePicker
          dateFormat="dd.MM.yyyy"
          customInput={<InputDate />}
          showPopperArrow={false}
          yearDropdownItemNumber={5}
          locale={locale}
          minDate={dayjs().toDate()}
          renderCustomHeader={(params) => <Header {...params} />}
          ref={ref}
          {...props}
        />
      </div>
    );
  },
);

export default StyledDatePicker;
