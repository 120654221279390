import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { COLORS, TYPOGRAPHY } from '@/constants';
import { IconButton } from '@/ui/components/IconButton';
import { UiButton } from '@/ui/components/UiButton';
import { ArrowDownIcon } from '@/ui/iconComponents/ArrowDownIcon';
import { ArrowUpIcon } from '@/ui/iconComponents/ArrowUpIcon';
import dayjs, { Dayjs } from 'dayjs';
import styled from 'styled-components';
import { closestMinutes } from '@/helpers/closestMinutes';
import { nearestMinutes } from '@/helpers/nearestMinutes';
import { LocaleContext } from '@/contexts/LocaleContext';
import { useLocaleData } from '@/hooks/useLocaleData';

type TimePopupProps = {
  value: string;
  onSetTime: (value: string) => void;
  onClose: () => void;
};

type Meridian = 'AM' | 'PM';

const TimePopup: FC<TimePopupProps> = ({ onSetTime, value, onClose }) => {
  const locale = useContext(LocaleContext);
  const dataLocale = useMemo(
    () => useLocaleData(locale, ['buttons']),
    [locale],
  );
  const [nearestValue, setNearestValue] = useState(
    nearestMinutes(5, dayjs(value)).toISOString(),
  );

  const [meridian, setMeridian] = useState<Meridian>(
    dayjs(value).format('A') as Meridian,
  );

  useEffect(() => {
    setMeridian(dayjs(nearestValue).locale('en').format('A') as Meridian);
  }, [nearestValue]);

  useEffect(() => {
    setNearestValue(nearestMinutes(5, dayjs(value)).toISOString());
  }, [value]);

  const convertToAM = () => {
    if (meridian !== 'AM') {
      const currentDate = dayjs(nearestValue);
      const currentHour = currentDate.hour();
      const newDate = currentDate.hour(currentHour - 12);
      setNearestValue(newDate.toISOString());
    }
  };

  const convertToPM = () => {
    if (meridian !== 'PM') {
      const currentDate = dayjs(nearestValue);
      const currentHour = currentDate.hour();
      const newDate = currentDate.hour(currentHour + 12);
      setNearestValue(newDate.toISOString());
    }
  };

  return (
    <Root>
      <TimeContainer>
        <TimeItemContainer>
          <StyledIconButton
            type="button"
            Icon={() => <ArrowUpIcon stroke={COLORS.gray} />}
            onClick={() =>
              setNearestValue(dayjs(nearestValue).add(1, 'hour').toISOString())
            }
          />

          <InputTime
            type="text"
            value={dayjs(nearestValue).format('hh')}
            readOnly
          />

          <StyledIconButton
            type="button"
            Icon={() => <ArrowDownIcon stroke={COLORS.gray} />}
            onClick={() =>
              setNearestValue(
                dayjs(nearestValue).subtract(1, 'hour').toISOString(),
              )
            }
          />
        </TimeItemContainer>
        <Separator>:</Separator>
        <TimeItemContainer>
          <StyledIconButton
            type="button"
            Icon={() => <ArrowUpIcon stroke={COLORS.gray} />}
            onClick={() =>
              setNearestValue(
                dayjs(nearestValue).add(5, 'minute').toISOString(),
              )
            }
          />

          <InputTime
            value={dayjs(nearestValue).format('mm')}
            type="text"
            readOnly
          />

          <StyledIconButton
            type="button"
            Icon={() => <ArrowDownIcon stroke={COLORS.gray} />}
            onClick={() =>
              setNearestValue(
                dayjs(nearestValue).subtract(5, 'minute').toISOString(),
              )
            }
          />
        </TimeItemContainer>

        <ButtonsMeridianContainer>
          <ButtonMeridianAm
            onClick={convertToAM}
            className={meridian === 'AM' ? 'active' : ''}
            type="button"
          >
            AM
          </ButtonMeridianAm>
          <ButtonMeridian
            onClick={convertToPM}
            className={meridian === 'PM' ? 'active' : ''}
            type="button"
          >
            PM
          </ButtonMeridian>
        </ButtonsMeridianContainer>
      </TimeContainer>

      <ButtonsContainer>
        {/* <CancelButton
          type="button"
          customTheme="primarySmall"
          text={dataLocale.buttons.close}
          onClick={onClose}
        /> */}

        <SetButton
          customTheme="primarySmall"
          type="button"
          text="Set time"
          onClick={() => {
            const currentHour = dayjs(nearestValue).get('h');
            const currentMinute = closestMinutes(
              dayjs(nearestValue).format('mm'),
            );
            const fullTime = `${
              currentHour < 10 ? '0' + currentHour : currentHour
            }:${currentMinute}`;

            onSetTime(fullTime);
            onClose();
          }}
        />
      </ButtonsContainer>
    </Root>
  );
};

const Root = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 999;
  max-width: 346px;
  margin-top: 10px;
  padding: 32px 24px;
  background-color: ${COLORS.white};
  border-radius: 12px;
  box-shadow: 0 3px 11px rgb(61 60 83 / 13%);
  @media (max-width: 1024px) {
    left: auto;
    right: 0;
  }
`;

const TimeContainer = styled.div`
  display: flex;
  align-items: center;
`;

const TimeItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 32px;
`;

const InputTime = styled.input`
  width: auto;
  max-width: 70px;
  margin: 0;
  padding: 0;
  padding-left: 6px;
  color: ${COLORS.black1};
  border: none;
  outline: none;

  &:focus {
    color: ${COLORS.purple};
  }

  ${TYPOGRAPHY.title2SemiBold48}
`;

const Separator = styled.div`
  margin: 0 14px;
  ${TYPOGRAPHY.title4Medium28}
  color: ${COLORS.lightGray};
`;

const StyledIconButton = styled(IconButton)`
  z-index: 2;
  display: flex;
  min-width: 24px;
  min-height: 24px;
  background: none;
  border-radius: 4px;
`;

const ButtonsMeridianContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 33px;
`;

const ButtonMeridian = styled.button`
  padding: 0;
  color: ${COLORS.gray};
  background: none;
  border: none;
  outline: none;
  cursor: pointer;

  ${TYPOGRAPHY.headlineMedium22};
  &.active {
    color: ${COLORS.black1};
  }
`;

const ButtonMeridianAm = styled(ButtonMeridian)`
  margin-right: 12px;
`;

const ButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-content: center;
  column-gap: 8px;
`;

const SetButton = styled(UiButton)`
  ${TYPOGRAPHY.captionMedium14};
`;

export default TimePopup;
