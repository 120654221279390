const minutes = [
  '00',
  '05',
  '10',
  '15',
  '20',
  '25',
  '30',
  '35',
  '40',
  '45',
  '50',
  '55',
];

export const closestMinutes = (currentMinutes: string) =>
  minutes.reduce(function (prev, curr) {
    return Math.abs(Number(curr) - Number(currentMinutes)) <
      Math.abs(Number(prev) - Number(currentMinutes))
      ? curr
      : prev;
  });
