import { LocaleContext } from '@/contexts/LocaleContext';
import { useLocaleData } from '@/hooks/useLocaleData';
import React, {
  FC,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ReactDatePicker from 'react-datepicker';
import { FieldRenderProps } from 'react-final-form';
import StyledDatePicker from '../../ui/components/StyledDatePicker';

const DatePickerField: FC<FieldRenderProps<Date, HTMLInputElement>> = ({
  input,
  meta,
}) => {
  const locale = useContext(LocaleContext);
  const dataLocale = useMemo(
    () => useLocaleData(locale, ['inputPlaceholders', 'buttons']),
    [locale],
  );
  const ref = useRef<ReactDatePicker>(null);
  const [selected, setSelected] = useState<Date | null | undefined>(
    input.value,
  );

  useEffect(() => {
    setSelected(input.value);
  }, [input.value]);

  return (
    <StyledDatePicker
      ref={ref}
      selected={selected}
      placeholderText={dataLocale.inputPlaceholders.date}
      onCalendarClose={() => {
        input.onChange(selected);
      }}
      onChange={(date) => setSelected(date as Date)}
      shouldCloseOnSelect={false}
    />
  );
};

export default DatePickerField;
