import { COLORS } from '@/constants';
import { Input } from '@/ui/components/Input';
import StyledInput from '@/ui/components/StyledInput/StyledInput';
import React, {
  FC,
  HTMLAttributes,
  InputHTMLAttributes,
  SyntheticEvent,
  useState,
} from 'react';
import { FieldRenderProps } from 'react-final-form';
import {
  formatPhoneNumber,
  isPossiblePhoneNumber,
} from 'react-phone-number-input';
import PhoneInput from 'react-phone-number-input/input';
import styled from 'styled-components';

interface InputPhoneProps extends FieldRenderProps<string, HTMLInputElement> {
  className?: string;
  isError?: boolean | string;
  isSuccess?: boolean;
}

const InputPhone: FC<InputPhoneProps> = ({
  className,
  input,
  meta,
  isError = false,
  isSuccess = false,
  ...rest
}) => {
  const validPhoneNumber = isPossiblePhoneNumber(input.value);
  const errorClass = input.value && !validPhoneNumber ? 'error' : '';
  const successClass = validPhoneNumber ? 'success' : '';

  return (
    <Root className={`${className} ${successClass}`}>
      <PhoneInput
        value={input.value}
        onChange={input.onChange}
        inputComponent={Input}
        className={errorClass}
        {...rest}
      />
      {isError && <ErrorText>{isError}</ErrorText>}
    </Root>
  );
};

const Root = styled.div`
  position: relative;
  &.success {
    ::before {
      content: '';
      position: absolute;
      right: 21px;
      top: 17px;
      width: 6px;
      height: 14px;
      border: solid ${COLORS.green};
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }
`;

const ErrorText = styled.span`
  margin-top: 4px;
  transition: transform 0.3s ease;
  font-size: 10px;
  line-height: 14px;
  color: ${COLORS.red};
`;

export default InputPhone;
