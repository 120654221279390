import dayjs from 'dayjs';
import { FC, useMemo } from 'react';
import { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import { date } from '@/utils';
import { StyledSelect } from '@/ui/components/StyledSelect';
import styled from 'styled-components';

const years = date.range(1970);

const Header: FC<ReactDatePickerCustomHeaderProps> = ({
  date,
  changeYear,
  changeMonth,
}) => {
  const globalLocaleData = dayjs.localeData();

  const month = globalLocaleData.months();
  const monthOptions = useMemo(
    () =>
      month.map((item, index) => ({
        label: item[0].toUpperCase() + item.slice(1),
        value: index,
      })),
    [month],
  );
  const yearOptions = useMemo(
    () => years.map((item) => ({ label: item.toString(), value: item })),
    [],
  );
  const selectedMonthOptions = useMemo(
    () => monthOptions[dayjs(date).get('month')],
    [date, monthOptions],
  );
  const selectedYearValue = useMemo(
    () => yearOptions.find((el) => el.value === dayjs(date).get('year')),
    [date, yearOptions],
  );

  return (
    <Root>
      <StyledSelect
        variant="secondary"
        options={monthOptions}
        isSearchable={false}
        value={selectedMonthOptions}
        onChange={(option: any) => {
          if (option?.value) {
            changeMonth(+option.value);
          }
        }}
      />
      <StyledSelect
        value={selectedYearValue}
        variant="secondary"
        options={yearOptions}
        isSearchable={false}
        onChange={(option: any) => {
          if (option?.value) {
            changeYear(+option.value);
          }
        }}
      />
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
`;

export default Header;
