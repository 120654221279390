import { COLORS } from '@/constants';
import { OptionType } from 'dayjs';
import {
  CSSObjectWithLabel,
  ControlProps,
  GroupBase,
  OptionProps,
  DropdownIndicatorProps,
  SingleValueProps,
} from 'react-select';
import { SelectStylesVariant } from './StyledSelect';

export const getCustomSelectStyles = (
  isValid: boolean,
  variant: SelectStylesVariant,
) => {
  switch (variant) {
    case 'primary':
      return {
        menu: (provided: CSSObjectWithLabel): CSSObjectWithLabel => ({
          ...provided,
          zIndex: 5,
          width: '100%',
          padding: 0,
          borderRadius: 12,
        }),
        menuList: (provided: CSSObjectWithLabel): CSSObjectWithLabel => ({
          ...provided,
          padding: 0,
          border: 'none',
          borderRadius: 12,
          boxShadow: '0 0 0 1px #f0f1f5',
        }),
        placeholder: (provided: CSSObjectWithLabel): CSSObjectWithLabel => ({
          ...provided,
          fontWeight: 300,
          color: COLORS.grayDarker2,
        }),
        singleValue: (provided: CSSObjectWithLabel): CSSObjectWithLabel => ({
          ...provided,
          fontWeight: 300,
        }),
        valueContainer: (provided: CSSObjectWithLabel): CSSObjectWithLabel => ({
          ...provided,
          maxHeight: 48,
          paddingTop: 10,
          paddingRight: 8,
          paddingBottom: 10,
          paddingLeft: 16,
        }),
        inputContainer: (provided: CSSObjectWithLabel): CSSObjectWithLabel => ({
          ...provided,
          margin: 0,
          paddingTop: 0,
          paddingBottom: 0,
        }),
        container: (provided: CSSObjectWithLabel): CSSObjectWithLabel => ({
          ...provided,
          minHeight: 'auto !important',
        }),
        indicatorSeparator: (
          provided: CSSObjectWithLabel,
        ): CSSObjectWithLabel => ({
          display: 'none',
        }),
        input: (provided: CSSObjectWithLabel): CSSObjectWithLabel => ({
          ...provided,
          minHeight: 'auto',
          outline: 'none',
          margin: 0,
          paddingTop: 0,
          paddingBottom: 0,
        }),
        control: (
          provided: CSSObjectWithLabel,
          state: ControlProps<unknown, boolean, GroupBase<unknown>>,
        ): CSSObjectWithLabel => ({
          ...provided,
          borderWidth: 1,
          borderStyle: 'solid',
          borderRadius: 12,
          backgroundColor: '#fff',
          borderColor: state.isDisabled
            ? '#E8E8E8 !important'
            : !isValid
            ? '#DD343F !important'
            : '#DCDDE6 !important',
          boxShadow: state.isFocused ? '0 0 0 1px #554ff5' : 'none',
        }),
        dropdownIndicator: (
          provided: CSSObjectWithLabel,
        ): CSSObjectWithLabel => ({
          ...provided,
          paddingTop: 14,
          paddingRight: 16,
          paddingBottom: 14,
          paddingLeft: 16,
          color: !isValid ? '#DD343F !important' : ' #4e4b66 !important',
        }),
        option: (
          provided: CSSObjectWithLabel,
          state: OptionProps<unknown, boolean, GroupBase<unknown>>,
        ): CSSObjectWithLabel => ({
          ...provided,
          position: 'relative',
          padding: 16,
          color: '#4e4b66',
          fontWeight: 300,
          backgroundColor:
            state.isFocused || state.isSelected
              ? '#f9f9fa !important'
              : '#fff !important',
          border: 'none',
        }),
      };
    case 'secondary':
      return {
        menu: (provided: CSSObjectWithLabel): CSSObjectWithLabel => ({
          ...provided,
          zIndex: 9999,
          width: '100%',
          padding: 0,
          borderRadius: 8,
          overflow: 'hidden',
          minWidth: '110px !important',
          background: '#fff',
        }),
        menuList: (provided: CSSObjectWithLabel): CSSObjectWithLabel => ({
          ...provided,
          padding: 0,
          border: 'none',
          paddingTop: '8px !important',
          paddingBottom: '8px !important',
        }),
        valueContainer: (provided: CSSObjectWithLabel): CSSObjectWithLabel => ({
          ...provided,
          maxHeight: 48,
          padding: 0,
        }),
        inputContainer: (provided: CSSObjectWithLabel): CSSObjectWithLabel => ({
          ...provided,
          margin: 0,
          paddingTop: 0,
          paddingBottom: 0,
        }),
        container: (provided: CSSObjectWithLabel): CSSObjectWithLabel => ({
          ...provided,
          minHeight: 'auto !important',
        }),
        indicatorSeparator: (
          provided: CSSObjectWithLabel,
        ): CSSObjectWithLabel => ({
          display: 'none',
        }),
        input: (provided: CSSObjectWithLabel): CSSObjectWithLabel => ({
          ...provided,
          minHeight: 'auto',
          outline: 'none',
          margin: 0,
          paddingTop: 0,
          paddingBottom: 0,
        }),
        control: (
          provided: CSSObjectWithLabel,
          state: ControlProps<OptionType, boolean, GroupBase<OptionType>>,
        ): CSSObjectWithLabel => ({
          ...provided,
          border: 'none',
          backgroundColor: 'transparent',
          boxShadow: 'none',
          fontSize: 14,
          fontWeight: 500,
          color: state.isFocused
            ? '#554FF5'
            : state.isDisabled
            ? '#d4d4d4'
            : '#14142A',
          background: state.isFocused ? '#F0F1F5' : 'none',
          padding: 8,
          borderRadius: 8,
        }),
        placeholder: () => ({
          gridArea: '1/1',
          margin: 0,
          paddingTop: 0,
          paddingBottom: 0,
          color: 'inherit',
        }),
        singleValue: (
          provided: CSSObjectWithLabel,
          state: SingleValueProps<OptionType, boolean, GroupBase<OptionType>>,
        ) => ({
          ...provided,
          gridArea: '1/1',
          color: 'inherit',
          border: 'none',
        }),
        dropdownIndicator: (
          provided: CSSObjectWithLabel,
          state: DropdownIndicatorProps<
            OptionType,
            boolean,
            GroupBase<OptionType>
          >,
        ): CSSObjectWithLabel => ({
          ...provided,
          padding: 0,
          width: 24,
          height: 24,
          marginLeft: 4,
          color: state.isFocused
            ? '#554FF5'
            : !isValid
            ? '#DD343F !important'
            : ' #14142A !important',
          display: 'flex',
          alignItems: 'center',
        }),
        option: (
          provided: CSSObjectWithLabel,
          state: OptionProps<OptionType, boolean, GroupBase<OptionType>>,
        ): CSSObjectWithLabel => ({
          ...provided,
          position: 'relative',
          paddingTop: 4,
          paddingRight: 16,
          paddingBottom: 4,
          paddingLeft: 16,
          color:
            state.isFocused && !state.isSelected
              ? '#554FF5 !important'
              : state.isSelected
              ? '#fff !important'
              : '#14142A !important',
          backgroundColor:
            state.isFocused && !state.isSelected
              ? '#F0F1F5 !important'
              : state.isSelected
              ? '#554FF5 !important'
              : '#fff !important',
          border: 'none',
          fontWeight: 400,
        }),
      };
  }
};
