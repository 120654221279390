import { COLORS } from '@/constants';
import React, { ButtonHTMLAttributes, FC, SVGProps } from 'react';
import styled from 'styled-components';

type IconButtonProps = {
  Icon: (props: SVGProps<SVGSVGElement>) => JSX.Element;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const IconButton: FC<IconButtonProps> = ({
  Icon,
  className,
  ...outerProps
}) => {
  return (
    <Root className={className} {...outerProps}>
      <Icon />
    </Root>
  );
};

const Root = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 56px;
  min-height: 56px;
  background-color: ${COLORS.lightGray2};
  border: none;
  border-radius: 16px;
  outline: none;
  transition: all 0.3s ease-out;

  &:hover {
    background-color: ${COLORS.lightGray8};
  }

  &:focus {
    box-shadow: 0 0 0 6px ${COLORS.lightGray8};
  }
`;

export default IconButton;
