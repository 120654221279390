import { COLORS, TYPOGRAPHY } from '@/constants';
import React, { FC, forwardRef, useMemo } from 'react';
import Select, { Props } from 'react-select';
import styled from 'styled-components';
import { getCustomSelectStyles } from './styles';

export type SelectStylesVariant = 'primary' | 'secondary';

type StyledSelectProps = {
  isValid?: boolean;
  className?: string;
  variant?: SelectStylesVariant;
} & Props;

const StyledSelect = forwardRef<any, StyledSelectProps>(
  ({ isValid = true, variant = 'primary', className, ...outerProps }, ref) => {
    const customStyles = useMemo(
      () => getCustomSelectStyles(isValid, variant),
      [isValid, variant],
    );

    return (
      <Root className={className}>
        <Select
          ref={ref}
          styles={{
            ...customStyles,
          }}
          classNamePrefix="reactSelect"
          className="reactSelectContainer"
          {...outerProps}
        />
      </Root>
    );
  },
);

const Root = styled.div`
  .reactSelect {
    .reactSelect__placeholder {
      margin: 0 !important;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      color: ${COLORS.grayDarker2} !important;
      ${TYPOGRAPHY.body2Light16};
    }

    .reactSelect__single-value {
      color: ${COLORS.black1} !important;
      border: 'none';

      ${TYPOGRAPHY.body2Light16};
    }

    &__single-value--is-disabled {
      color: #d4d4d4 !important;
    }

    .reactSelect__option {
      &::before {
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 95%;
        height: 1px;
        background: #f0f1f5;
        transform: translateX(-50%);
        content: '';
      }

      &:last-child {
        &::before {
          display: none;
        }
      }
    }

    .reactSelect__option--is-selected {
      &::before {
        display: none;
      }
    }

    .reactSelect__control--is-disabled {
      .reactSelect__placeholder {
        color: #d4d4d4 !important;
      }
    }
  }
`;

export default StyledSelect;
