import { LocaleContext } from '@/contexts/LocaleContext';
import { nearestMinutes } from '@/helpers/nearestMinutes';
import { useLocaleData } from '@/hooks/useLocaleData';
import { useOnClickOutside } from '@/hooks/useOnClickOutside';
import { Input } from '@/ui/components/Input';
import dayjs from 'dayjs';
import React, {
  FC,
  useCallback,
  useState,
  useRef,
  useMemo,
  useContext,
} from 'react';
import { FieldRenderProps } from 'react-final-form';
import styled from 'styled-components';
import { TimePopup } from '../TimePopup';

const splitTime = (targetValue: string, mainValue: string) => {
  const [hours, minutes] = targetValue.split(':');
  const currentTime = dayjs(mainValue)
    .set('h', +hours)
    .set('m', +minutes);
  return currentTime;
};

const InputTimeField: FC<FieldRenderProps<string, HTMLInputElement>> = ({
  input,
  meta,
}) => {
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [value, setValue] = useState<string>(
    input.value ? input.value : dayjs().toISOString().toString(),
  );
  const rootRef = useRef<HTMLDivElement>(null);
  const locale = useContext(LocaleContext);
  const dataLocale = useMemo(
    () => useLocaleData(locale, ['inputPlaceholders']),
    [locale],
  );

  useOnClickOutside(rootRef, () => setIsOpenPopup(false));

  const handleChangeInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      input.onChange(e.target.value);
      const currentTime = splitTime(e.target.value, value);
      if (currentTime.isValid()) {
        const nearestTime = nearestMinutes(5, dayjs(currentTime)).toISOString();

        setValue(nearestTime);
      }
    },
    [input, value],
  );

  const startsWithTwo = (input.value ? input.value[0] : '') === '2';
  const firstNumberOfHours = /[0-2]/;
  const secondNumberOfHours = startsWithTwo ? /[0-3]/ : /[0-9]/;
  const firstNumberOfMinutes = /[0-5]/;
  const secondNumberOfMinutes = /[0-9]/;

  const mask = [
    firstNumberOfHours,
    secondNumberOfHours,
    ':',
    firstNumberOfMinutes,
    secondNumberOfMinutes,
  ];

  return (
    <Root ref={rootRef}>
      <Input
        placeholder={dataLocale.inputPlaceholders.time}
        {...input}
        onChange={handleChangeInput}
        error={meta.error}
        value={input.value}
        mask={mask}
        onFocus={() => {
          setIsOpenPopup(true);
          const currentTime = splitTime(input.value, value);

          if (currentTime.isValid()) {
            setValue(currentTime.toISOString());
          }
        }}
      />

      {isOpenPopup && (
        <TimePopup
          onSetTime={(value) => input.onChange(value)}
          value={value}
          onClose={() => {
            setIsOpenPopup(false);
          }}
        />
      )}
    </Root>
  );
};

const Root = styled.div`
  position: relative;
`;

export default InputTimeField;
